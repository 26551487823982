import React from 'react'
import { Alert, Box, Button, Heading, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Formik } from 'formik'
import { loginAuth } from 'store/authentication.reducer'
import { Logo } from 'components/Logo'
import { InputForm, useWindowHeight } from 'chakra-lib'

const validateInput = (value) => {
  let error
  if (!value) {
    error = 'Поле не может быть пустым'
  }
  return error
}

export const Login = () => {
  const windowHeight = useWindowHeight()
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.authentication.loading)
  const refreshAuthenticated = useSelector((state) => state.authentication.refreshAuthenticated)
  const error = useSelector((state) => state.authentication.error)
  const themeBg = useColorModeValue('gray.50', 'gray.800')
  const themeBgContainer = useColorModeValue('white', 'gray.700')
  const themeBorderWidth = useColorModeValue('1px', 'none')
  const handleForm = async (values) => {
    await dispatch(loginAuth(values.user, values.password))
  }
  return (
    <Box
      width="100%"
      height={`${windowHeight}px`}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor={themeBg}
    >
      <Box
        width="370px"
        backgroundColor={themeBgContainer}
        p="40px"
        borderRadius={8}
        borderWidth={themeBorderWidth}
      >
        <Box
          display="flex"
          justifyContent="center"
          marginBottom="20px"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Logo boxSize="120px" mb={6}/>
          <Heading as="h3" size="md" mb={2}>
            IDpay Admin
          </Heading>
          <Text mb={2} fontSize="0.9rem">
            Введите логин и пароль
          </Text>
        </Box>
        {error && (
          <Alert
            flexDirection="column"
            mb={4}
            status="error"
            borderRadius={6}
            justifyContent="center"
          >
            <Text fontWeight="semibold">Ошибка авторизации</Text>
          </Alert>
        )}
        <Formik
          initialValues={{
            user: '',
            password: '',
          }}
          onSubmit={handleForm}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Stack spacing={3} mb={5}>
                <Field
                  name="user"
                  autoFocus
                  component={InputForm}
                  validate={validateInput}
                  placeholder="Логин"
                />
                <Field
                  name="password"
                  type="password"
                  component={InputForm}
                  validate={validateInput}
                  placeholder="Пароль"
                />
              </Stack>
              <Box>
                <Button
                  type="submit"
                  colorScheme="orange"
                  width="100%"
                  isLoading={loading || refreshAuthenticated}
                >
                  Войти
                </Button>
              </Box>
              <Box mt={8} color="gray.400" fontSize="xs" style={{ textAlign: 'center' }}>
                24.10.24
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  )
}
