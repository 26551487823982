import { Field, Formik } from 'formik'
import moment from 'moment'
import { useMemo, useState } from 'react'

import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import { Heading, InputForm, SelectForm, TextAreaForm } from 'chakra-lib'
import { Form, FormGrid, FormSection } from 'chakra-lib/layout'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import toast from 'utils/toast'
import {
  errorCode,
  formFields,
  formFieldsLabel,
  passportCountry,
  passportCountryOptions,
} from './UserEditForm.constants'
import { getPassportSeriesAndNumber } from './UserEditForm.utils'
import { validationSchema } from './UserEditForm.validation'
import { useUpdateUser } from './services'
import { useSyncUser } from './services/useSyncUser'
import { userIdentification } from 'model/user.model'

export const UserEditForm = () => {
  const item = useSelector((state) => state.user.item)
  const loading = useSelector((state) => state.user.loading)
  const passportData = getPassportSeriesAndNumber(item.passport, item.identityCountry)
  const [currentErrorInfo, setCurrentErrorInfo] = useState()
  const isDuplicate = currentErrorInfo?.error === errorCode.DUPLICATE
  const addressFields = item?.registrationAddress?.addressFields

  const { refetch: syncUserData, isLoading: isSyncingUser } = useSyncUser({ id: item.id })

  const { mutate: sendUserData, isLoading: userDataIsMutate } = useUpdateUser({
    onSuccess: () => {
      setCurrentErrorInfo(undefined)
    },
    onError: (err) => {
      const data = err.response.data
      const message = data.message || err.message

      setCurrentErrorInfo(data)

      if (data.error === errorCode.DUPLICATE) {
        toast.showWarning({
          title: 'Пользователь с таким номером уже существует',
          description: message,
        })
      } else {
        toast.showError({
          title: 'Произошла ошибка при обновлении данных пользователя',
          description: message,
        })
      }
    },
  })

  const fieldsIsDisabled = userDataIsMutate

  const handleForm = async (values) => {
    const {
      series,
      number,
      country,
      regionName,
      regionType,
      districtName,
      settlementName,
      settlementType,
      streetName,
      streetType,
      houseNumber,
      block,
      unitNumber,
      ...rest
    } = values
    const forceValues = isDuplicate ? { force: true } : undefined

    sendUserData({
      id: item.id,
      passport: series + number,
      registrationAddress: {
        addressFields: {
          country,
          regionName,
          regionType,
          districtName,
          settlementName,
          settlementType,
          streetName,
          streetType,
          houseNumber,
          block,
          unitNumber,
        },
      },
      ...forceValues,
      ...rest,
    })
  }

  const initialValues = useMemo(
    () => ({
      series: passportData?.series || undefined,
      number: passportData?.number || undefined,
      dateIssue: item.dateIssue ? moment(item.dateIssue).format('YYYY-MM-DD') : undefined,
      issueOrgCode: item.issueOrgCode || undefined,
      issue: item.issue || undefined,
      identityCountry: item.identityCountry,
      phone: item.phone || undefined,
      email: item.email || undefined,
      firstName: item.firstName || undefined,
      lastName: item.lastName || undefined,
      middleName: item.middleName || undefined,
      country: addressFields?.country || undefined,
      regionName: addressFields?.regionName || undefined,
      regionType: addressFields?.regionType || undefined,
      districtName: addressFields?.districtName || undefined,
      settlementName: addressFields?.settlementName || undefined,
      settlementType: addressFields?.settlementType || undefined,
      streetName: addressFields?.streetName || undefined,
      streetType: addressFields?.streetType || undefined,
      houseNumber: addressFields?.houseNumber || undefined,
      block: addressFields?.block || undefined,
      unitNumber: addressFields?.unitNumber || undefined,
    }),
    [item]
  )

  const userSyncIsDisavbled = ![
    userIdentification.FULL,
    userIdentification.FULL_DOSSIER,
  ].includes(item.identification)

  return (
    <VStack alignItems="flex-start" maxW="1000px">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleForm}
        validationSchema={validationSchema}
      >
        {(formProps) => {
          const { values } = formProps

          return (
            <Form
              w="100%"
              onSubmit={formProps.handleSubmit}
              footer={
                <HStack spacing={3} ml="auto">
                  <Button
                    colorScheme="orange"
                    isLoading={loading || isSyncingUser}
                    onClick={() => syncUserData()}
                    isDisabled={userSyncIsDisavbled}
                  >
                    Синхронизировать данные с СКБ
                  </Button>

                  <Button
                    type="submit"
                    colorScheme="orange"
                    isLoading={loading || userDataIsMutate || isSyncingUser}
                    isDisabled={!formProps.isValid || isDuplicate}
                  >
                    Обновить
                  </Button>
                </HStack>
              }
            >
              <Heading mb={4}>ФИО</Heading>
              <FormGrid columns={2} alignItems="none" mb={6}>
                <Field
                  name={formFields.firstName}
                  label={formFieldsLabel[formFields.firstName]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.lastName}
                  label={formFieldsLabel[formFields.lastName]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.middleName}
                  label={formFieldsLabel[formFields.middleName]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
              </FormGrid>

              <Heading mb={4}>Паспортные данные</Heading>
              <FormGrid columns={2} alignItems="none">
                <VStack>
                  <Field
                    name={formFields.identityCountry}
                    label={formFieldsLabel[formFields.identityCountry]}
                    component={SelectForm}
                    placeholder="Укажите страну"
                    options={passportCountryOptions}
                    isDisabled
                  />
                  {values.country === passportCountry.ARM && (
                    <Text color="orange">
                      Внимание! Для паспорта РА серией являются две первые буквы, номером -
                      оставшиеся семь цифр. Организация, выдавшая документ должна содержать
                      трехзначный код.
                    </Text>
                  )}
                </VStack>
                <FormSection>
                  <FormGrid columns={2}>
                    <Field
                      name={formFields.series}
                      label={formFieldsLabel[formFields.series]}
                      component={InputForm}
                      isDisabled={fieldsIsDisabled}
                    />
                    <Field
                      name={formFields.number}
                      label={formFieldsLabel[formFields.number]}
                      component={InputForm}
                      isDisabled={fieldsIsDisabled}
                    />
                  </FormGrid>
                  <FormGrid columns={2}>
                    <Field
                      name={formFields.dateIssue}
                      label={formFieldsLabel[formFields.dateIssue]}
                      component={InputForm}
                      placeholder="ГГГГ-ММ-ДД"
                      isDisabled={fieldsIsDisabled}
                    />
                    {values.country === passportCountry.RU && (
                      <Field
                        name={formFields.issueOrgCode}
                        label={formFieldsLabel[formFields.issueOrgCode]}
                        component={InputForm}
                        placeholder="XXX-XXX"
                        isDisabled={fieldsIsDisabled}
                      />
                    )}
                  </FormGrid>
                  <Field
                    name={formFields.issue}
                    label={formFieldsLabel[formFields.issue]}
                    component={TextAreaForm}
                    isDisabled={fieldsIsDisabled}
                  />
                </FormSection>
              </FormGrid>

              <Heading mb={4}>Адрес регистрации</Heading>
              <FormGrid mb={4} columns={3} alignItems="none">
                <Field
                  name={formFields.country}
                  label={formFieldsLabel[formFields.country]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.regionName}
                  label={formFieldsLabel[formFields.regionName]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.regionType}
                  label={formFieldsLabel[formFields.regionType]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.districtName}
                  label={formFieldsLabel[formFields.districtName]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.settlementName}
                  label={formFieldsLabel[formFields.settlementName]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.settlementType}
                  label={formFieldsLabel[formFields.settlementType]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.streetName}
                  label={formFieldsLabel[formFields.streetName]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.streetType}
                  label={formFieldsLabel[formFields.streetType]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.houseNumber}
                  label={formFieldsLabel[formFields.houseNumber]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.block}
                  label={formFieldsLabel[formFields.block]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.unitNumber}
                  label={formFieldsLabel[formFields.unitNumber]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
              </FormGrid>

              <Heading mb={4}>Контакты</Heading>
              <FormGrid columns={2} alignItems="none">
                <Field
                  name={formFields.phone}
                  label={formFieldsLabel[formFields.phone]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
                <Field
                  name={formFields.email}
                  label={formFieldsLabel[formFields.email]}
                  component={InputForm}
                  isDisabled={fieldsIsDisabled}
                />
              </FormGrid>
              {isDuplicate && (
                <Box>
                  <HStack mt="10px" spacing="5px">
                    <Text color="red.400">Такой номер или email уже существует у пользователя</Text>
                    <Text color={'blue.300'}>
                      <Link to={`./${currentErrorInfo?.data.id}`} target="_blank">
                        {currentErrorInfo?.data.id}
                      </Link>
                    </Text>
                  </HStack>
                  <HStack mt="10px">
                    <Text>Заблокировать дубликат?</Text>
                    <Button
                      size="sm"
                      colorScheme="orange"
                      onClick={() => formProps.handleSubmit()}
                      minW="60px"
                    >
                      Да
                    </Button>
                    <Button size="sm" onClick={() => setCurrentErrorInfo(undefined)}>
                      Отмена
                    </Button>
                  </HStack>
                </Box>
              )}
            </Form>
          )
        }}
      </Formik>
    </VStack>
  )
}
